import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faTwitter } from "@fortawesome/free-brands-svg-icons"
import ShareLink from "react-facebook-share-link"
import TwitterShareLink from "react-twitter-share-link"

const Share = ({ showTitle = true, url = "" }) => {
  return (
    <div>
      {showTitle && <p className="text-xl font-medium mb-4">Share</p>}
      <div className="social-icons flex gap-4">
        <ShareLink link={url}>
          {link => (
            <a
              href={link}
              target="_blank"
              className={
                "rounded-full flex items-center justify-center bg-blue-700 text-white w-10 h-10"
              }
            >
              <FontAwesomeIcon icon={faFacebookF} className={"w-4 h-4"} />
            </a>
          )}
        </ShareLink>
        <TwitterShareLink link={url}>
          {link => (
            <a
              href={link}
              target="_blank"
              className={
                "rounded-full flex items-center justify-center bg-blue-900 text-white w-10 h-10"
              }
            >
              <FontAwesomeIcon icon={faTwitter} className={"w-4 h-4"} />
            </a>
          )}
        </TwitterShareLink>
      </div>
    </div>
  )
}

export default Share
