import * as React from "react"
import { Link, graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { ArrowNarrowLeftIcon } from "@heroicons/react/solid"
import { convertUTCToTimezone, Pad, returnWeekday } from "../utils/functions"
import ProgramEpisodeItem from "../components/program/episode-item"
import PlaceholderWide from "../images/show-placeholder-wide.png"
import { StationContext } from "../context/station-context"

import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import moment from "moment"
import Share from "../components/global/share"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ProgramTemplate = ({ data, location }) => {
  const {station} = React.useContext(StationContext)
  const program = data.program
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const image = getImage(program.bigImg)
  const bgImage = convertToBgImage(image)
  const episodes = data.allEpisode.edges
  const [actualEpisodes, setActualEpisodes] = React.useState([])

  React.useEffect(() => {
    const newEpisodes = episodes.filter(item => {
      const startStream = convertUTCToTimezone(
        item.node.show_start,
        null,
        station.timezone,
        ""
      )
      if (
        moment().isAfter(startStream) &&
        item.node.program.slug === program.slug
      ) {
        return item
      }
    })
    setActualEpisodes(newEpisodes)
  }, [episodes, setActualEpisodes])

//   console.lg("This part", data)

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title={program.name} description={program.introduction || ""} />
      <article
        className="blog-program relative mt-8 mb-16"
        itemScope
        itemType="http://schema.org/Article"
      >
        <div className="relative w-full">
          <BackgroundImage
            Tag="section"
            // Spread bgImage into BackgroundImage:
            {...bgImage}
            preserveStackingContext
            className={"bg-image"}
          >
            <div style={{ minHeight: 500, minWidth: "100%" }}></div>
          </BackgroundImage>
          {!program.featuredImg && (
            <img
              src={PlaceholderWide}
              alt={program.name}
              className={"w-full h-auto mb-8"}
              style={{ width: "100%", height: "auto" }}
            />
          )}
          <header className={"relative program-header p-4 lg:p-8 mt-4 mb-4"}>
            {/* {image && (
            <GatsbyImage
              className={"w-100 h-auto"}
              image={image}
              alt={program.name}
            />
          )} */}
            <h1
              itemProp="headline "
              className={"text-xl lg:text-6xl font-bold"}
            >
              {program.name}
            </h1>
            {program.introduction && (
              <p className={"mt-2 lg:mt-4"}>{program.introduction}</p>
            )}
            {program.presenter_string && (
              <p itemProp="description" className="mb-2 mt-2">
                <span className="font-bold">PRESENTED BY:</span>{" "}
                {program.presenter_string && program.presenter_string}
              </p>
            )}
            {program.genre_string && (
              <p itemProp="description" className="mb-2">
                <span className="font-bold">GENRE:</span>{" "}
                {program.genre_string && program.genre_string}
              </p>
            )}
          </header>
        </div>
        <div className={"article-content mt-16"}>
          <div className={"grid grid-cols-12  gap-4"}>
            <div className="col-span-12 lg:col-span-8">
              {program.slots && (
                <table className="shadow-lg bg-white w-full mb-8">
                  <thead>
                    <tr>
                      <th className={"bg-blue-100 border text-left px-8 py-4"}>
                        Weekday
                      </th>
                      <th className={"bg-blue-100 border text-left px-8 py-4"}>
                        Start
                      </th>
                      <th className={"bg-blue-100 border text-left px-8 py-4"}>
                        Finish
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {program.slots &&
                      program.slots.map((slot, i) => (
                        <tr key={`${program.id}${i}`} className={"text-sm"}>
                          <td className={"border px-8 py-4"}>
                            {returnWeekday(slot.weekday_start)}
                          </td>

                          <td className={"border px-8 py-4"}>
                            {Pad(
                              slot.hour_start > 12
                                ? slot.hour_start - 12
                                : slot.hour_start
                            )}
                            :{Pad(slot.minute_start)}
                            {slot.hour_start >= 12 ? "PM" : "AM"}
                          </td>
                          <td className={"border px-8 py-4"}>
                            {Pad(
                              slot.hour_end > 12
                                ? slot.hour_end - 12
                                : slot.hour_end
                            )}
                            :{Pad(slot.minute_end)}
                            {slot.hour_end >= 12 ? "PM" : "AM"}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}

              <section
                dangerouslySetInnerHTML={{ __html: program.bio }}
                itemProp="articleBody"
              />
              {actualEpisodes.length > 0 && (
                <div className="mt-4 mb-4">
                    <h4 className="mb-4 font-semibold text-lg">Recent Episodes:</h4>
                  {actualEpisodes.length > 0 &&
                    actualEpisodes.map((episode, i) => (
                      <ProgramEpisodeItem
                        episode={episode}
                        key={`${i}-${episode.node.id}`}
                      />
                    ))}
                </div>
              )}
            </div>
            <div className="col-span-12 lg:col-span-4">
              {image ? (
                <GatsbyImage
                  className={"w-full h-auto mb-8"}
                  image={image}
                  alt={program.name}
                />
              ) : (
                <img
                  src={PlaceholderWide}
                  alt={program.name}
                  className={"w-full h-auto mb-8"}
                />
              )}
            </div>
            <div className="col-span-12">
              <Share url={`/${program.slug}`} />
            </div>
          </div>
        </div>
        <hr className={"mt-4 lg:mt-16"} />

        <footer className="mt-4 lg:mt-16 text-center lg:text-left w-full">
          <Link
            to={`/programs`}
            className={
              "   text-brand font-semibold   py-2    items-center gap-2 flex border-brand hover:border-transparent "
            }
          >
            <ArrowNarrowLeftIcon className="w-8 h-8" />
            <label>Return to Program List</label>
          </Link>
        </footer>
      </article>
    </Layout>
  )
}

export default ProgramTemplate

export const pageQuery = graphql`
  query ProgramBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    program(slug: { eq: $slug }) {
      ...Program
      slots {
        created_at
        duration
        hour_end
        hour_start
        id
        is_active
        minute_end
        minute_start
        program_id
        seconds_from_sunday
        station_id
        weekday_end
        weekday_start
      }
      bigImg: featuredImg {
        url
        childImageSharp {
          gatsbyImageData(
            width: 960
            height: 450
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
    allEpisode {
      edges {
        node {
          ...Episode
        }
      }
    }
  }
`
